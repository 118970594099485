@import '~bulma/sass/utilities/initial-variables.sass';
@import '_variables.sass';

.has-letter-spacing-small {
  letter-spacing: -0.035rem !important; }

.is-size-8 {
  font-size: 0.625rem; }

.filter-item {
  font-size: 0.75rem !important;
  height: 2.5rem !important;
  padding: 0.5rem 1.5rem !important; }

// screen size and dpi helpers
// html
//// border: 10px solid red
//// @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)
////     border: 10px solid blue

//// @media (min-width: 200em)
////     border: 10px solid green !important

// has columns fixes mobile horizontal columns scrolling
.has-columns {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.is-justified-right {
  justify-content: flex-end; }

.has-text-check-value-unknown {
  color: $checklistItemValueUnknown !important; }

.has-text-check-value-check {
  color: $checklistItemValueCheck !important; }

.has-text-check-value-ok {
  color: $checklistItemValueOk !important; }

.has-text-check-value-correct {
  color: $checklistItemValueCorrect !important; }

.has-text-check-value-skip {
  color: $checklistItemValueSkip !important; }

.has-text-check-value-na {
  color: $checklistItemValueNa !important; }

.has-text-check-value-abort {
  color: $checklistItemValueAbort !important; }


.has-text-process-state-started {
  color: $success !important; }

.has-text-process-state-completed {
  color: $success !important; }

.has-text-process-state-cancelled {
  color: $warning !important; }

.has-text-process-state-aborted {
  color: $danger !important; }

.has-text-occurrence-open {
  color: $occurrenceOpen !important; }

.has-text-occurrence-defined {
  color: $occurrenceDefined !important; }



.has-background-check-value-unknown {
  background-color: $checklistItemValueUnknown !important; }

.has-background-check-value-check {
  background-color: $checklistItemValueCheck !important; }

.has-background-check-value-ok {
  background-color: $checklistItemValueOk !important; }

.has-background-check-value-correct {
  background-color: $checklistItemValueCorrect !important; }

.has-background-check-value-skip {
  background-color: $checklistItemValueSkip !important; }

.has-background-check-value-na {
  background-color: $checklistItemValueNa !important; }

.has-background-check-value-abort {
  background-color: $checklistItemValueAbort !important; }


.has-background-process-state-started {
  background-color: $success !important; }

.has-background-process-state-completed {
  background-color: $success !important; }

.has-background-process-state-cancelled {
  background-color: $warning !important; }

.has-background-process-state-aborted {
  background-color: $danger !important; }

